import React from 'react';
import { GAMViewContext, TemplateProps } from '../parser';
import { PathService } from '../services';
import { GAMView } from '../views';

const GAM: React.FunctionComponent<TemplateProps<GAMViewContext>> = ({
  location,
  pageContext,
}) => {
  PathService.set(location.pathname);
  return (
    <GAMView
      page={JSON.parse(pageContext.page)}
      content={JSON.parse(pageContext.content)}
    />
  );
};

export default GAM;
